import httpUtil from "@/utils/httpUtil";

/**新增联系人 */
export const addBusOfficePersonMore =  params => httpUtil.post("/api/assPc/FunctionIPC/addBusOfficePersonMore", params);

/** 修改联系人  */
export const upateBusOfficePerson =  params => httpUtil.post("/api/assPc/FunctionIPC/upateBusOfficePerson", params);

/** 删除联系人  */
export const delBusOfficePerson =  params => httpUtil.post("/api/assPc/FunctionIPC/delBusOfficePerson", params);

/** 查询联系人  */
export const queryBusOfficePerson =  params => httpUtil.post("/api/assPc/FunctionIPC/queryBusOfficePerson", params);



/** 新增商会  */
export const addBusinessOffice =  params => httpUtil.post("/api/assPc/FunctionIPC/addBusinessOffice", params);

/** 查询商会  */
export const queryBusinessOfficePage =  params => httpUtil.post("/api/assPc/FunctionIPC/queryBusinessOfficePage", params);

/** 修改商会  */
export const updateBusinessOffice =  params => httpUtil.post("/api/assPc/FunctionIPC/updateBusinessOffice", params);

/** 删除商会  */
export const delBusinessOffice =  params => httpUtil.post("/api/assPc/FunctionIPC/delBusinessOffice", params);


