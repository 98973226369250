<template>
  <div>
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基础信息" name="first">
        <basic_information v-if="activeName == 'first'"  :jurisdiction="jurisdiction"/>
      </el-tab-pane>

      <el-tab-pane label="异地商协会" name="second">
        <another_list v-if="activeName == 'second'" :jurisdiction="jurisdiction"/>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>

<script>
import basic_information from "./another/basicInformation.vue";
import another_list from "./another/anotherList.vue";
import { mapState } from 'vuex';

export default {
  name: "localOffice",
  components: {
    basic_information,
    another_list
  },

  data() {
    return {
      activeName: "first",
      jurisdiction:false,
    };
  },
  computed: {
    ...mapState(['routerArr']),

  },
  created(){
    if(this.$route.query.name){
      this.activeName = this.$route.query.name
    }
  },
  watch:{
    routerArr(val){
      this.jurisdiction = val.includes("040")
      console.log(this.jurisdiction,'this.jurisdiction')
    },
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped></style>










